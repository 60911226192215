var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-3" },
    [
      _vm.filters && _vm.filters.length > 0
        ? _c(
            "div",
            [
              _c("AESearchFilter", {
                attrs: {
                  "filter-dialog": _vm.filterDialog,
                  "filter-counter": _vm.filterCounter,
                  filters: _vm.filters,
                },
                on: {
                  "filter-data": function ($event) {
                    return _vm.execute($event)
                  },
                  "filter-dialog": function ($event) {
                    return _vm.$emit("filter-dialog", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        {
          attrs: {
            flat: "",
            outlined: !_vm.noOutline,
            dark: _vm.$vuetify.theme.dark,
            light: !_vm.$vuetify.theme.dark,
          },
        },
        [
          _c(
            "v-data-table",
            _vm._g(
              _vm._b(
                {
                  staticClass: "elevation-0",
                  attrs: {
                    dark: _vm.$vuetify.theme.dark,
                    light: !_vm.$vuetify.theme.dark,
                    headers: _vm.availableHeaders,
                    items: _vm.records,
                    loading: _vm.dataLoading,
                    "hide-default-footer":
                      _vm.disablePagination ||
                      _vm.records.length === 0 ||
                      (_vm.itemsPerPageOptions &&
                        _vm.itemsPerPageOptions.length === 1 &&
                        _vm.itemsPerPageOptions[0] === -1),
                    options: _vm.options,
                    "server-items-length": _vm.totalRecords,
                    "fixed-header": "",
                    "calculate-widths": "",
                    "disable-pagination": _vm.disablePagination,
                    "show-expand": _vm.showExpand,
                    "show-group-by": _vm.availableHeaders.some(function (
                      header
                    ) {
                      return header.groupable
                    }),
                    "multi-sort": "",
                    "headers-length": _vm.availableHeaders.length,
                    height: !_vm.noHeight
                      ? _vm.$vuetify.breakpoint.mdAndUp
                        ? _vm.$vuetify.breakpoint.height -
                          _vm.$vuetify.application.top -
                          _vm.$vuetify.application.footer -
                          _vm.appConfig.window.barHeight -
                          (_vm.itemsPerPageOptions.filter(function (x) {
                            return _vm.noMaxAsAll ? x !== -1 : true
                          }).length > 0
                            ? 59
                            : 37) -
                          40 -
                          _vm.heightAdj
                        : _vm.heightLimit
                      : _vm.heightLimit,
                    "footer-props": {
                      showFirstLastPage: true,
                      showCurrentPage: true,
                      disableItemsPerPage:
                        _vm.itemsPerPageOptions.filter(function (x) {
                          return _vm.noMaxAsAll ? x !== -1 : true
                        }).length === 0,
                      itemsPerPageOptions: _vm.itemsPerPageOptions.filter(
                        function (x) {
                          return _vm.noMaxAsAll ? x !== -1 : true
                        }
                      ),
                    },
                  },
                  on: {
                    "update:options": function ($event) {
                      _vm.options = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header.action",
                        fn: function () {
                          return [_vm._v(" ")]
                        },
                        proxy: true,
                      },
                      _vm.rowActionsFiltered
                        ? {
                            key: "item.action",
                            fn: function (ref) {
                              var item = ref.item
                              return [
                                _c(
                                  "v-menu",
                                  {
                                    attrs: { bottom: "", left: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        dark: "",
                                                        icon: "",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticStyle: {
                                                        color: "black",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "mdi-dots-vertical"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _c(
                                      "v-list",
                                      _vm._l(
                                        _vm.rowActionsFiltered,
                                        function (action) {
                                          return _c(
                                            "v-list-item",
                                            { key: action.name },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  "link" === action.type
                                                    ? [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: _vm.executeOnRow(
                                                                action.name,
                                                                item
                                                              ),
                                                              exact: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      action.icon
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  action.tooltip
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : "slink" === action.type
                                                    ? [
                                                        _c(
                                                          "router-link",
                                                          {
                                                            attrs: {
                                                              to: action.link,
                                                              exact: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "mr-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      action.icon
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  action.tooltip
                                                                )
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    : [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            staticClass: "mr-2",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.executeOnRow(
                                                                  action.name,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  action.icon
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              action.tooltip
                                                            )
                                                          ),
                                                        ]),
                                                      ],
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          }
                        : null,
                      {
                        key: "no-results",
                        fn: function () {
                          return [
                            _vm.dataLoading
                              ? _c("span", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ae$lng("forms.search.loadingDataWait")
                                    )
                                  ),
                                ])
                              : _vm.dataError
                              ? _c("span", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ae$lng(
                                        "forms.search.noResultFoundError"
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ae$lng("forms.search.noResultsFound")
                                    )
                                  ),
                                ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _vm.dataLoading
                              ? _c("span", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ae$lng("forms.search.loadingDataWait")
                                    )
                                  ),
                                ])
                              : _vm.dataError
                              ? _c("span", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ae$lng(
                                        "forms.search.noResultFoundError"
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", { staticClass: "text-caption" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.ae$lng("forms.search.noDataFound")
                                    )
                                  ),
                                ]),
                          ]
                        },
                        proxy: true,
                      },
                      !_vm.simpleLoading
                        ? {
                            key: "loading",
                            fn: function () {
                              return [
                                _vm.$vuetify.breakpoint.mdAndUp
                                  ? _c(
                                      "v-sheet",
                                      [
                                        _c("v-skeleton-loader", {
                                          staticClass:
                                            "ma-0 pa-0 mx-0 my-0 fill-height",
                                          attrs: {
                                            loading: "",
                                            width: "100%",
                                            type: "table-tbody",
                                            types: {
                                              "table-cell": "text",
                                              "table-row": "table-cell@5",
                                              "table-row-divider":
                                                "table-row, divider",
                                              "table-tbody":
                                                "table-row-divider@5",
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("br"),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-body-1 font-weight-light font-italic",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.ae$lng(
                                          "forms.search.loadingDataWait"
                                        )
                                      )
                                    ),
                                  ]
                                ),
                                _c("br"),
                                _vm._v("  "),
                              ]
                            },
                            proxy: true,
                          }
                        : null,
                      _vm._l(_vm.$scopedSlots, function (index, name) {
                        return {
                          key: name,
                          fn: function (data) {
                            return [_vm._t(name, null, null, data)]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.$attrs.value,
                    callback: function ($$v) {
                      _vm.$set(_vm.$attrs, "value", $$v)
                    },
                    expression: "$attrs.value",
                  },
                },
                "v-data-table",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }